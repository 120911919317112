<template>
  <div class="column-flex">
    <div class="search-plate flex">
      <div class="flex-left">
        <div>
          <span>患者ID：</span>
          <a-input v-model:value="searchParam.patientId" placeholder="请输入患者ID" maxlength="50" />
        </div>
        <div>
          <span>记录时间：</span>
          <a-range-picker v-model:value="searchParam.rangeTime" format="YYYY/MM/DD HH:mm:ss" show-time />
        </div>
        <a-button @click="querySearch" type="primary">
          查询
        </a-button>
        <a-button @click="reset">
          重置
        </a-button>
      </div>
    </div>
    <div class="container">
      <a-table :columns="columns" :row-key="(record) => record.uuid" :data-source="examList" :pagination="pagination"
        :loading="loading" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'result'">
            {{ getResult(record) }}
          </template>
          <template v-if="column.key === 'createdName'">
            {{ maskedString(record?.nurse?.nickname) }}
          </template>
          <template v-if="column.key === 'createdAt'">
            {{ getTime(record?.createdAt) }}
          </template>
          <template v-if="column.key === 'operate'">
            <a @click="showModal(record)">查看</a>
          </template>
        </template>
      </a-table>
    </div>
    <a-modal :width="520" title="详情" :visible="visible" @cancel="handleOk">
      <div>
        <div v-for="(item, index) in detailInfo" :key="index" class="result-item">
          <span>{{ item.title }}</span>
          <span> {{ recordList[item.key] ? '通过' : '未通过' }}</span>
        </div>
      </div>
      <template #footer>
        <a-button type="primary" @click="handleOk">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { listEvaluation } from '@/api/caseResult'
import { Modal } from 'ant-design-vue';

import moment from 'moment';
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn';

const columns = [
  {
    title: '患者ID',
    dataIndex: 'patientId',
    key: 'patientId'
  },
  {
    title: '筛查结果',
    dataIndex: 'result',
    key: 'result'
  },
  {
    title: '记录人',
    dataIndex: 'createdName',
    key: 'createdName'
  },
  {
    title: '记录时间',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: '操作',
    dataIndex: 'operate',
    key: 'operate'
  },
]

const searchParam = reactive({
  patientId: '',
  rangeTime: []
})
const examList = ref([])
const recordList = ref({})
const detailInfo = ref([{
  key: 'ventilationAndOxygenationState',
  title: "足够的通气与氧合(如:Pa02/Fi02>200; Fi02<0.5; PEEP<10<cmH20; pH>7.3，PaCO2<6.5kPa);",
}, {
  key: 'cardiovascularState',
  title: "稳定的心血管系统:心率、血压稳定;不需(或最小限度的)血管活性药;",
}, {
  key: 'noHighFever',
  title: "无高热;",
}, {
  key: 'noAcidosis',
  title: "无明显的呼吸性酸中毒;",
}, {
  key: 'hemoglobinState',
  title: "血色素正常或接近正常;",
}, {
  key: 'mentality',
  title: "足够的精神活动(可唤醒的，没有连续的镇静剂输注);",
}, {
  key: 'metabolicStatus',
  title: "稳定的代谢状态;",
}, {
  key: 'selfCoughingAbility',
  title: "有足够排痰能力。",
}])
const visible = ref(false)

function querySearch() {
  pagination.current = 1;
  getDataList()
}

const getTime = (time) => {
  if (time) {
    const convertedTime = new Date(time);
    convertedTime.setHours(convertedTime.getHours() + 8);
    const formattedTime = convertedTime.toLocaleString('zh-CN', { timeZone: 'UTC' });
    return formattedTime;
  } else {
    return ''
  }
}

const showModal = (showInfo) => {
  recordList.value = JSON.parse(JSON.stringify(showInfo))

  visible.value = true;
};

const handleOk = (e) => {
  visible.value = false;
};

const pagination = reactive({
  showLessItems: true,
  showQuickJumper: true,
  showSizeChanger: true,
  showTotal: (total) => `共 ${total} 条`,
  current: 1,
  pageSize: 10,
  total: 0
})

function handleTableChange(p) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize

  getDataList();
}

const loading = ref(false)

function getDataList() {
  let { patientId, rangeTime } = searchParam
  let timeStamp = rangeTime.length > 0 ? dayjs(rangeTime[1]).startOf('day').add(rangeTime[1].$H, 'hour').add(rangeTime[1].$m, 'minute').add(rangeTime[1].$s, 'second').valueOf() : '';

  let params = {
    patientId,
    startTime: rangeTime.length > 0 ? dayjs(rangeTime[0]).startOf('day').add(rangeTime[0].$H, 'hour').add(rangeTime[0].$m, 'minute').add(rangeTime[0].$s, 'second').valueOf() : '',
    endTime: timeStamp ? timeStamp.toString().slice(0, -3) + '999' : '',
    _pageSize: pagination.pageSize,
    _page: pagination.current,
  }
  listEvaluation(params).then((res) => {
    examList.value = res.results
    pagination.total = res.pagination.total
  })
}

function getResult(record) {
  if (record.ventilationAndOxygenationState && record.cardiovascularState && record.noHighFever && record.noAcidosis && record.hemoglobinState && record.mentality && record.metabolicStatus && record.selfCoughingAbility) {
    return '通过撤机筛查'
  } else {
    return '未通过撤机筛查'
  }
}

function maskedString(str) {
  if (str) {
    if (str.length < 2) {
      return str;
    } else if (str.length == 2) {
      return str[0] + '*';
    } else {
      return str[0] + '*' + str[str.length - 1];
    }
  } else {
    return '';
  }
}

function reset() {
  searchParam.patientId = '';
  searchParam.rangeTime = [];

  getDataList();
}

onMounted(() => {
  getDataList();
})
</script>
<style lang="less" scoped>
.column-flex {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - 105px);
}

.container {
  flex: 1;
}

.search-plate {
  background: #fff;
  padding: 25px 30px 10px;
  margin-bottom: 15px;
  border-radius: 5px;

  .ant-btn {
    margin-left: 10px;
  }

  .ant-btn-primary {
    background: #4687FE;
  }

  :deep .ant-select {
    width: 200px !important;
    margin-right: 10px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.flex-left {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  >div {
    display: flex;
    align-items: center;
    margin: 0 20px 15px 0;

    >span {
      text-wrap: nowrap;
    }
  }

  >.ant-btn {
    margin-bottom: 15px;
  }
}


.result-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;

  span:nth-child(1) {
    max-width: 90%;
  }
}
</style>