<template>
  <div class="login-container">
    <div class="login-container-form">
      <img src="@/assets/img/logo.png" class="login-container-logo" alt="">
      <div class="login-container-title">呼吸机撤机风险评估系统</div>
      <a-form @submit="handleSubmit" @submit.prevent>
        <a-form-item label="账号" v-bind="validateInfos.identifier">
          <a-input v-model:value="modelRef.identifier" placeholder="请输入您的账号">
            <template v-slot:prefix>
              <img src="@/assets/img/user.png" alt="">
            </template>
          </a-input>
        </a-form-item>
        <a-form-item label="密码" v-bind="validateInfos.password">
          <a-input v-model:value="modelRef.password" :type="showPwd?'text':'password'" placeholder="请输入您的密码">
            <template v-slot:prefix>
              <img src="@/assets/img/pwd.png" alt="">
            </template>
            <template #suffix>
              <EyeOutlined v-if="showPwd" @click="showPwd =false" />
              <EyeInvisibleOutlined v-else @click="showPwd=true" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit">
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
  import { computed, reactive, ref } from 'vue'
  import { useStore } from 'vuex'
  import { Form, message } from 'ant-design-vue';
  import router from '@/router';
  import { useRoute } from 'vue-router';
  import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons-vue';

  export default {
    name: 'Login',
    components: { EyeOutlined, EyeInvisibleOutlined },
    setup() {
      const store = useStore()
      const route = useRoute();

      const showPwd = ref(false);

      const loginForm = Form.useForm;

      const redirect = computed(() => {
        const query = route.query
        return (query && query.redirect) || '/'
      })

      const modelRef = reactive({
        identifier: '',
        password: '',
      });

      const { resetFields, validate, validateInfos } = loginForm(
        modelRef,
        reactive({
          identifier: [
            {
              required: true,
              message: '请输入账号',
            },
          ],
          password: [
            {
              required: true,
              message: '请输入密码',
            },
          ],
        }),
      );

      const handleSubmit = () => {
        validate()
          .then(async res => {
            await store.dispatch('user/login', modelRef)
            router.push('/index')
          })
          .catch(err => {
            if (err.response.data.error.message == "Invalid identifier or password") {
              message.error("账号或密码不正确！")
            }
          });
      };

      return {
        showPwd,
        modelRef,
        validateInfos,
        handleSubmit,
      }
    },
  }
</script>
<style lang="less" scoped>
  .login-container {
    width: 100%;
    height: 100vh;
    background: url('~@/assets/img/login_bg.png');
    background-size: cover;

    &-form {
      position: fixed;
      top: 50%;
      left: 50%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 600px;
      height: 758px;
      max-height: 80vh;
      min-height: 550px;
      padding: 6vh;
      background: #fff;
      background-size: 100% 100%;
      border-radius: 10px;
      box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
      transform: translate(-50%, -50%);
    }

    &-logo {
      width: 14vh;
      height: 14vh;
    }

    &-title {
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 500;
      font-size: 22px;
      color: #000000;
      margin: 40px 0 50px;
      margin: 2.9vh 0 3.9vh;
      text-wrap: nowrap;
    }

    .ant-form {
      width: 400px;

      .ant-form-item {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        min-height: 64px;
      }

      :deep .ant-form-item-label {
        margin-bottom: 13px;

        &>label {
          height: 16px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 16px;
          color: #666666;

          &::after {
            display: none;
          }
        }
      }

      .ant-form-item-control-input {
        min-height: none;
      }

      :deep .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none !important;
      }

      .ant-input-affix-wrapper {
        width: 400px;
        height: 46px;
        border-radius: 4px;
      }

      .ant-input-prefix {
        margin-right: 8px;

        img {
          width: 16px;
          height: 16px;
        }
      }

      .anticon-eye-invisible,
      .anticon-eye {
        color: #999;
      }
    }

    .ant-col {
      width: 100%;
      padding: 0 10px 0 10px;
    }

    .ant-input {
      height: 35px;
    }

    .ant-btn {
      width: 400px;
      height: 46px;
      border-radius: 4px;
      background: #4687FE;
      border-color: #4687FE;
      margin-top: 11px;
    }
  }

  :deep .ant-form-horizontal .ant-form-item-control {
    flex: unset !important;
  }
</style>